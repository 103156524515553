import { ApolloError, gql, useSubscription } from "@apollo/client";
import useHandleWsError from "@/hooks/useHandleWsError";
import useGetPathKey from "@/hooks/useGetPathKey";
import * as Sentry from "@sentry/nextjs";
import { useFilterContextSelector } from "@/components/Common/Filters/FilterContext";
import useParseUserFilterToCommonFilterType from "@/hooks/useParseUserFilterToCommonFilterType";
import { mapPathKeyToToolEnum, PICKUSERFILTER_FRAGMENT, PickUserFilterType } from "./user-filters/common";
import { useUserFiltersContextSelector } from "./user-filters/UserFiltersContext";
import { useUserSettingsContextSelector } from "./user-settings/UserSettingsContext";
import { useUser } from "./UserContext";
import { USER_FRAGMENT, UserType } from "./common";

const USER_SUBSCRIPTION = gql`
  subscription user {
    user {
      ... on UserMessageFilterUpdatedType {
        filterId
        filter {
          ...PickUserFilterTypeFragment
        }
      }

      ... on UserSubscriptionUpdatedType {
        user {
          ...UserFragment
        }
      }
    } 
  }
  ${PICKUSERFILTER_FRAGMENT}
  ${USER_FRAGMENT}
`;

type UserMessageFilterUpdatedType = {
  filterId: string;
  filter: PickUserFilterType
}

type UserSubscriptionUpdatedType = {
  user: UserType;
}

type UserSubscription = {
  user: UserMessageFilterUpdatedType | UserSubscriptionUpdatedType
};


function UserSubscription() {
  const { data: userDefaultData } = useUser();
  const handleWsError = useHandleWsError();
  const [setUserFilters, selectedFilterId] = useUserFiltersContextSelector((ctx) => [ctx.setUserFilters, ctx.selectedFilterId]);
  const setAllFilter = useFilterContextSelector((ctx) => ctx.setAllFilter);
  const setUserSettingFromJson = useUserSettingsContextSelector((ctx) => ctx.setUserSettingFromJson);
  const { updateUser } = useUser();
  const pathKey = useGetPathKey();
  const parseUserFilterToCommonFilterType = useParseUserFilterToCommonFilterType();

  const {
    restart,
  } = useSubscription<UserSubscription>(USER_SUBSCRIPTION, {
    onData: ({ data }) => {
      const userData = data.data?.user;
      if (!userData) return;

      // for delete filter
      if ('filterId' in userData) {
        setUserFilters((prev) => {
          const newPrev = prev?.filter((prevData) => prevData.id !== userData.filterId);
          return newPrev;
        }
        )
      }

      // for add / update filter
      if ('filter' in userData) {
        try {
          const updatedFilter = userData.filter;
          const toolEnum = mapPathKeyToToolEnum(pathKey);
          if (updatedFilter.toolEnum !== toolEnum) return;
          setUserFilters((prev) => {
            const newPrev = prev?.filter((prevData) => prevData.id !== updatedFilter.id) || [];
            return newPrev.concat(updatedFilter);
          }
          )
          // For other tab update the filter state as well, for current tab it is updated before hand
          if (selectedFilterId === updatedFilter.id && document?.visibilityState !== 'visible') {
            setAllFilter(parseUserFilterToCommonFilterType(updatedFilter.filters, updatedFilter.json, pathKey, updatedFilter.lastModifiedPackageTypeEnum))
          }

        } catch (error) {
          Sentry.withScope((scope) => {
            scope.setExtra("userId", userDefaultData?.userId);
            scope.setExtra("user Email", userDefaultData?.emailAddress);
            scope.setExtra("API Data", JSON.stringify(data));
            scope.setExtra("Error", error);
            Sentry.captureException(
              new Error(
                `Custom Error - Error in User subscription after filter edit / add :- ${userDefaultData?.userId}`,
              ),
            );
          });
        }
      }

      // For User Update Type
      if ('user' in userData) {
        updateUser(userData.user);
        setUserSettingFromJson(userData.user.settings);
      }
    },
    onError: (err: ApolloError) => {
      if (err.message === "One Connection Max") {
        restart();
      }
      handleWsError(err);
    },
  });

  return null;
}

export default UserSubscription;
