"use client";

import { Provider as ReduxProvider } from "react-redux";
import React from "react";
import store from "@/store";
import { ApolloWrapper } from "@/apollo/ApolloWrapper";
import { ThemeProvider } from "@/theme/ThemeContext";
import { UserProvider } from "@/user/UserContext";
import { UserQueryType } from "@/user/common";
import { UserSettingsProvider } from "@/user/user-settings/UserSettingsContext";
import { PickUserRestrictionType } from "@/user/UserRestrictions/common";
import { UserFiltersProvider } from "@/user/user-filters/UserFiltersContext";
import MigrateFilters from "@/user/user-filters/MigrateFilters/MigrateFilters";
import UserSubscription from "@/user/UserSubscription";
import { FilterProvider } from "../Common/Filters/FilterContext";
import { PauseDataProvider } from "../Common/PauseDataContext";
import ActivityChecker from "../ActivityChecker/ActivityChecker";
import WebSocketStatus from "../WebScoketStatus/WebSocketStatus";
import BuildIdChecker from "./BuildIdChecker";
import CustomErrorBoundary from "../ErrorBoundary/CustomErrorBoundary";


type AppProvidersProps = {
  children: React.ReactNode;
  themeCookie: string | undefined;
  user: UserQueryType | null | undefined;
  restrictions: PickUserRestrictionType[] | null | undefined;
  userToken?: string;
};

function AppProviders(props: AppProvidersProps) {
  const { children, themeCookie, user, restrictions, userToken } = props;
  // if (!userTokens?.anonymousToken) return null;

  return (
    <ReduxProvider store={store}>
      <ThemeProvider serverCookie={themeCookie}>
        {/* NOTE: UserProvider must be "above" ApolloWrapper because it
      sets apiToken */}
        <UserProvider user={user} restrictions={restrictions} userToken={userToken}>
          <ApolloWrapper userToken={userToken}>
            <UserSettingsProvider settings={user?.user?.settings}>
              <UserFiltersProvider>
                <FilterProvider>
                  <MigrateFilters />
                  {user?.user?.userId && <UserSubscription />}
                  <CustomErrorBoundary />
                  <PauseDataProvider>
                    <BuildIdChecker />
                    <ActivityChecker />
                    <WebSocketStatus />
                    {children}
                  </PauseDataProvider>
                </FilterProvider>
              </UserFiltersProvider>
            </UserSettingsProvider>
          </ApolloWrapper>
        </UserProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default AppProviders;
