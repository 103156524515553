import { useEffect, useRef } from 'react'
import * as Sentry from "@sentry/nextjs";
import { useUser } from '@/user/UserContext';
import useMigrateFilters from './useMigrateFilters';
import { useUserFiltersContextSelector } from '../UserFiltersContext';

function MigrateFilters() {
  const checkFilterAndMigrate = useMigrateFilters();
  const [userFilterFetched, userFilters, userFilterLoading] = useUserFiltersContextSelector((ctx) => [ctx.userFilterFetched, ctx.userFilters, ctx.userFilterLoading]);
  const isFilterMigrated = useRef(false);
  const { data: userData } = useUser();

  useEffect(() => {
    if (userFilterLoading) return;
    if (!userFilterFetched) return;
    if (userFilters?.length) return;
    if (isFilterMigrated.current) return;
    try {
      checkFilterAndMigrate();
      isFilterMigrated.current = true;
    } catch (error) {
      isFilterMigrated.current = false;
      Sentry.withScope((scope) => {
        scope.setExtra("userId", userData?.userId);
        scope.setExtra("user Email", userData?.emailAddress);
        scope.setExtra("Error", error);
        Sentry.captureException(
          new Error(
            `Custom Error - Error Converting Filter to Migrate for userId:- ${userData?.userId}`,
          ),
        );
      });
    }
  }, [userFilters, userFilterLoading, checkFilterAndMigrate, userFilterFetched, userData?.userId, userData?.emailAddress])

  return null;
}

export default MigrateFilters