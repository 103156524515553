import { useCallback } from "react";
import useGetApiVariablesFromFiltersFn from "@/hooks/useGetApiVariablesFromFilters";
import { GetApiVariablesFromFiltersType } from "@/hooks/useGetApiVariablesFromFilters.common";
import { CommonFilterType, getDefaultFilterValuesByPathKey } from "@/components/Common/Filters/commonFilterType";
import { v4 as uuidv4 } from "uuid";
import useRemoveSubscriptionVariableFromCommonFilterToJson from "@/hooks/useRemoveSubscriptionVariableFromCommonFilterToJson";
import { loadState, saveState } from "@/utis/localStorage";
import { useUser } from "@/user/UserContext";
import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { useUserSettingsContextSelector } from "../../user-settings/UserSettingsContext";
import {
  ADD_USER_FILTER,
  AddUserFilter,
  AddUserFilterVariables,
  InputCreatePickUserFilterType,
  mapToolEnumKeyToPathKey,
  PickUserFilterType,
  ToolEnumType,
} from "../common";
import { PageFilterProfileType } from "../../user-settings/common";
import { DEFAULT_FILTER_VALUE, DEFAULT_NAME } from "../../user-settings/pageFilterHelper";

const KEY = "USER_FILTERS";

function generateVariablesForFilter(
  index: number,
  tool: ToolEnumType,
  getApiVariablesFromFilters: GetApiVariablesFromFiltersType,
  removeSubscriptionVariableFromCommonFilterJson: (filter: CommonFilterType) => string,
  filProps?: PageFilterProfileType,
  overriddenBy?: string,
): InputCreatePickUserFilterType {
  const pathKey = mapToolEnumKeyToPathKey(tool);
  const arbFil = filProps || DEFAULT_FILTER_VALUE;
  const convertedFilter = {
    name: arbFil.name,
    toolEnum: tool,
    icon: arbFil.filterIcon,
    color: arbFil.color,
    order: index,
    filters: getApiVariablesFromFilters(pathKey, pathKey, arbFil.value || getDefaultFilterValuesByPathKey(pathKey))
      .requestVariableForPage,
    json: arbFil.value ? removeSubscriptionVariableFromCommonFilterJson(arbFil.value) : undefined,
    isDefault: (!arbFil.overriddenBy && arbFil.name === DEFAULT_NAME) || overriddenBy === arbFil.name,
  };

  Sentry.addBreadcrumb({
    category: "info",
    message: `Converted filter for user`,
    data: { tool, oldFilter: arbFil, newFilter: convertedFilter },
    level: "log",
  });
  return convertedFilter;
}

const migateFilterForPage = (
  pageFilter: PageFilterProfileType[],
  tool: ToolEnumType,
  getApiVariablesFromFilters: GetApiVariablesFromFiltersType,
  removeSubscriptionVariableFromCommonFilterJson: (filter: CommonFilterType) => string,
): InputCreatePickUserFilterType[] => {
  if (pageFilter) {
    let overriddenBy: string;
    pageFilter.forEach((arbFil) => {
      if (arbFil.overriddenBy) overriddenBy = arbFil.overriddenBy;
    });
    return pageFilter.map((arbFil, i) =>
      generateVariablesForFilter(
        i,
        tool,
        getApiVariablesFromFilters,
        removeSubscriptionVariableFromCommonFilterJson,
        arbFil,
        overriddenBy,
      ),
    );
  }
  return [
    generateVariablesForFilter(
      0,
      tool,
      getApiVariablesFromFilters,
      removeSubscriptionVariableFromCommonFilterJson,
      undefined,
      undefined,
    ),
  ];
};

function useMigrateFilters() {
  const allPageFilters = useUserSettingsContextSelector((ctx) => ctx.allPageFilters);
  const { isLoggedIn, data: userData } = useUser();

  const getApiVariablesFromFilters = useGetApiVariablesFromFiltersFn();
  const removeSubscriptionVariableFromCommonFilterJson = useRemoveSubscriptionVariableFromCommonFilterToJson();

  const [handleAddUserFilter] = useMutation<AddUserFilter, AddUserFilterVariables>(ADD_USER_FILTER, {});
  const addUserFilter = useCallback(
    (filters: InputCreatePickUserFilterType[]) => {
      if (isLoggedIn) {
        const promises = filters.map((filter) => {
          Sentry.addBreadcrumb({
            category: "info",
            message: `Migrating filter for  user email:- ${userData?.emailAddress}, id${userData?.userId}`,
            data: { filter },
            level: "log",
          });
          return handleAddUserFilter({
            variables: { filter },
            onError: (err) => {
              if (err.message.includes("Filter Name already used")) return;
              Sentry.withScope((scope) => {
                scope.setExtra("userId", userData?.userId);
                scope.setExtra("user Email", userData?.emailAddress);
                scope.setExtra("Filter Name", filter.name);
                scope.setExtra("Error Message", err.message);
                scope.setExtra("Error", err);
                Sentry.captureException(
                  new Error(
                    `Custom Error - Error Migrating user Filter userId:- ${userData?.userId} with Filter Name :- ${filter.name}`,
                  ),
                );
              });
            },
          });
        });
        return Promise.all(promises);
        // eslint-disable-next-line no-else-return
      } else {
        const prevFilters = loadState<PickUserFilterType[]>(KEY) || [];
        const newFilters = filters.map((newFilter) => ({
          ...newFilter,
          id: uuidv4(),
          createdDateTime: Date.now(),
          lastModifiedDateTime: Date.now(),
        }));
        saveState(KEY, prevFilters.concat(newFilters));
        return Promise.all(newFilters);
      }
    },
    [handleAddUserFilter, isLoggedIn, userData?.emailAddress, userData?.userId],
  );

  const checkFilterAndMigrate = useCallback(() => {
    Sentry.addBreadcrumb({
      category: "info",
      message: `User Migration started for user email:- ${userData?.emailAddress}, id${userData?.userId}`,
      data: { allPageFilters, userSettings: userData?.settings },
      level: "log",
    });
    // For ARBITRAGE PAGE
    const arbMigratedFilter = migateFilterForPage(
      allPageFilters.arbitrages,
      ToolEnumType.ARBITRAGE,
      getApiVariablesFromFilters,
      removeSubscriptionVariableFromCommonFilterJson,
    );

    // For MIDDLES PAGE
    const middleMigratedFilter = migateFilterForPage(
      allPageFilters.middles,
      ToolEnumType.MIDDLE,
      getApiVariablesFromFilters,
      removeSubscriptionVariableFromCommonFilterJson,
    );

    // For FREE Bet PAGE
    const freeBetMigratedFilter = migateFilterForPage(
      allPageFilters.freebet,
      ToolEnumType.FREE_BET_CONVERTER,
      getApiVariablesFromFilters,
      removeSubscriptionVariableFromCommonFilterJson,
    );

    // For Low Holds PAGE
    const lowHoldMigratedFilter = migateFilterForPage(
      allPageFilters.lowhold,
      ToolEnumType.LOW_HOLD,
      getApiVariablesFromFilters,
      removeSubscriptionVariableFromCommonFilterJson,
    );

    // For Expected Value PAGE
    const evMigratedFilter = migateFilterForPage(
      allPageFilters.expectedvalue,
      ToolEnumType.EV,
      getApiVariablesFromFilters,
      removeSubscriptionVariableFromCommonFilterJson,
    );

    // For odds PAGE
    const oddsMigratedFilter = migateFilterForPage(
      allPageFilters["odds-screen"],
      ToolEnumType.ODD_SCREEN,
      getApiVariablesFromFilters,
      removeSubscriptionVariableFromCommonFilterJson,
    );

    // For Sportsbook PAGE
    const sportsbookMigratedFilter = migateFilterForPage(
      allPageFilters.game,
      ToolEnumType.SPORTSBOOK,
      getApiVariablesFromFilters,
      removeSubscriptionVariableFromCommonFilterJson,
    );

    addUserFilter(
      arbMigratedFilter.concat(
        middleMigratedFilter,
        freeBetMigratedFilter,
        lowHoldMigratedFilter,
        evMigratedFilter,
        oddsMigratedFilter,
        sportsbookMigratedFilter,
      ),
    ).then(() => {
      window.location.reload();
    });
  }, [
    addUserFilter,
    allPageFilters,
    getApiVariablesFromFilters,
    removeSubscriptionVariableFromCommonFilterJson,
    userData?.emailAddress,
    userData?.settings,
    userData?.userId,
  ]);

  return checkFilterAndMigrate;
}

export default useMigrateFilters;
