import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  Box,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { formatDateTimeYear } from '@/utis/formatDateTime';
import { useAppSelector } from '@/store/store';
import { convertUTCToLocalTime } from '@/utis/ApiDateFormats';
import SensorsIcon from "@mui/icons-material/Sensors";
import formatLeagueName from '@/utis/formatLeagueName';
import { motion } from 'framer-motion';
import { getLeagueFromTypename } from '@/utis/formatTypeNameLeague';
import { toTitleCase } from '@/utis/formatNameToTitleCase';
import { useRouter } from 'next/navigation';
import { SEARCH_LIVE_QUERY } from './common';
import SelectMenu from '../SelectMenu';
import StyledTypography from "../StyledComponents/EllipseTypography";
import { IGameType } from '../Odds/common';
import PageSpinner from '../PageSpinner';
import LiveData from '../LiveData/LiveData';
import { LiveDataDesign } from '../LiveData/DesignEnum';
import { getTeamName } from '../SportsBook/helper';
import { League } from '../Common/Enums/LeagueEnum';

function SearchLive() {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const sports = useAppSelector((state) => state.constantsReducer.sports);
  const gameLiveData = useAppSelector((state) => state.gameLiveDataReducer);
  const theme = useTheme();

  useEffect(() => {
    const handler = setTimeout(() => {
      if (query.length > 2) {
        setDebouncedQuery(query);
      } else {
        setDebouncedQuery('');
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  const { loading, error, data } = useQuery(SEARCH_LIVE_QUERY, {
    variables: { query: debouncedQuery },
    skip: !debouncedQuery,
  });

  const handleClose = () => {
    setDebouncedQuery('');
  };

  const router = useRouter();

  const handleGameClick = (league: League, gameId: string) => {
    router.push(`/game/${league}/${gameId}`);
  };

  const handlePlayerClick = (league: League, gameId: string, playerId: string) => {
    router.push(`/game/${league}/${gameId}?panelValue=3&selectedPlayerId=${playerId}`);
  };

  return (
    <Box>
      <SelectMenu>
        <SelectMenu.TextField
          id="search-input"
          onSearchChange={setQuery}
          onClose={handleClose}
        />
        <SelectMenu.Modal width={315} sx={{
          backgroundColor: theme.palette.customBackground.cardHeader,
          borderRadius: "16px",
          border: `1px solid ${theme.palette.grayScale.eightpercent}`,
          maxHeight: 412,
          overflow: "hidden",
        }}>
          {!data && <PageSpinner />}
          <SelectMenu.List getHeight={() => 400} scrollBarMargin={20}>
            <motion.div
              initial={{ opacity: 0, y: -100, scale: 1 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -100, scale: 1 }}
              transition={{ type: "spring", stiffness: 200, damping: 20, duration: 0.5 }}
            >
              {!loading && data && data.search.live.games.length === 0 && data.search.live.players.length === 0 && (
                <SelectMenu.Item sx={{
                  borderRadius: "16px",
                }}>
                  <Typography variant="label" textAlign="center" py={2}>
                    No results found
                  </Typography>
                </SelectMenu.Item>
              )}

              {error && <Typography variant="caption" color="error">Error: {error.message}</Typography>}

              {data && data.search.live.games.length > 0 &&
                (<Box height={34} display="flex" alignItems="center" sx={{
                  backgroundColor: theme.palette.customBackground.cardHeader,
                  borderRadius: "16px 16px 0px 0px",
                }}>
                  <Typography variant="sofiaSansHeader" fontSize={18} sx={{
                    px: 2.5,
                  }}>Games</Typography>
                </Box>
                )}
              <Box sx={{
                backgroundColor: theme.palette.customBackground.cardHeader,
                borderRadius: "16px 16px 0px 0px",
                border: `1px solid ${theme.palette.grayScale.sixpercent}`,
                borderTop: "none",
                borderBottom: "none"
              }}>
                {data?.search?.live?.games.map((game: IGameType, index: number) => {
                  const { awayTeam, homeTeam, startDateTime, __typename, id } = game;
                  const league = getLeagueFromTypename(__typename);
                  const backgroundColor = index % 2 === 0 ? theme.palette.primary.main : theme.palette.customBackground.cards;
                  return (
                    <React.Fragment key={index}>
                      <SelectMenu.Item
                        sx={{
                          backgroundColor, borderRadius: index === 0 ? "16px 16px 0px 0px" : "0px",
                          borderTop: index === 0 ? `1px solid ${theme.palette.grayScale.sixpercent}` : "none",
                        }}
                        onClick={() => handleGameClick(league, id)}
                      >
                        <Stack direction="row" alignItems="center" width="100%" height={65} pl={1}>
                          <Stack flexDirection="column" flex={1} maxWidth={130}>
                            <StyledTypography variant="body2">
                              {getTeamName(awayTeam, league, false)}
                            </StyledTypography>
                            <StyledTypography variant="body2">
                              {getTeamName(homeTeam, league, false)}
                            </StyledTypography>
                            <StyledTypography variant="smallText" color="text.secondary" fontWeight="bold">
                              {formatLeagueName(league, sports)}
                            </StyledTypography>
                          </Stack>
                          <Stack flexDirection="column" px={1} flex={1}>
                            {gameLiveData[id] ? (
                              <Box position="relative" width="100px" p={1}>
                                <Box sx={{ transform: 'scale(1)', transformOrigin: 'top left' }}>
                                  <LiveData gameId={id} leagueId={league} design={LiveDataDesign.DEFAULT} startTime={startDateTime} showAdditionalData={false} />
                                </Box>
                                <Box position="absolute" top={6} right={-35}
                                  sx={{
                                    backgroundColor: theme.palette.grayScale.sixpercent,
                                    borderRadius: "8px",
                                    px: 0.3,
                                    m: 0,
                                    height: "22px"
                                  }}>
                                  <SensorsIcon color="error" sx={{
                                    fontSize: 16,
                                  }} />
                                </Box>
                              </Box>
                            ) : (
                              <StyledTypography color="text.secondary" variant="label" textAlign="center">
                                {startDateTime ? (
                                  <>
                                    {formatDateTimeYear(convertUTCToLocalTime(startDateTime)!).split(',')[0]}
                                    <br />
                                    <b>{formatDateTimeYear(convertUTCToLocalTime(startDateTime)!).split(',')[1]}</b>
                                  </>
                                ) : null}
                              </StyledTypography>
                            )}
                          </Stack>
                        </Stack>
                      </SelectMenu.Item>
                    </React.Fragment>
                  );
                })}
              </Box>

              <Box sx={{
                backgroundColor: data && data.search.live.games.length % 2 === 0 ? theme.palette.customBackground.cards : theme.palette.primary.main,
              }}>
                {/* player Results */}
                <Box sx={{
                  backgroundColor: theme.palette.customBackground.cardHeader,
                  borderRadius: "16px 16px 0px 0px",
                  border: `1px solid ${theme.palette.grayScale.sixpercent}`,
                  borderTop: "none",
                  borderBottom: "none"
                }}>
                  {data && data.search.live.players && data.search.live.players.length > 0 && (
                    <Box height={34} display="flex" alignItems="center" sx={{
                      backgroundColor: theme.palette.customBackground.cardHeader,
                      borderRadius: "16px 16px 0px 0px",
                      borderTop: `1px solid ${theme.palette.grayScale.sixpercent}`,
                    }}>
                      <Typography variant="sofiaSansHeader" fontSize={18} sx={{ px: 2.5, }}>
                        Players
                      </Typography>
                    </Box>
                  )}
                  <Box sx={{
                    backgroundColor: theme.palette.customBackground.cardHeader,
                    borderRadius: "16px 16px 0px 0px",
                    border: `1px solid ${theme.palette.grayScale.sixpercent}`,
                    borderTop: "none",
                    borderBottom: "none"
                  }}>
                    {data && data.search.live.players.map((player: { player: { name: string, id: number }, game: IGameType }, index: number) => {
                      const { name, id: playerId } = player.player;
                      const { __typename, awayTeam, homeTeam, startDateTime, id: gameId } = player.game;
                      const league = getLeagueFromTypename(__typename);
                      const backgroundColor = index % 2 === 0 ? theme.palette.primary.main : theme.palette.customBackground.cards;

                      return (
                        <React.Fragment key={index}>
                          <SelectMenu.Item
                            sx={{
                              backgroundColor, borderRadius: index === 0 ? "16px 16px 0px 0px" : "0px",
                              borderTop: index === 0 ? `1px solid ${theme.palette.grayScale.sixpercent}` : "none",
                            }}
                            onClick={() => handlePlayerClick(league, gameId, playerId.toString())}
                          >
                            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" height={40} pl={1} pr={2}>
                              <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ flex: 1.5, pr: 1 }}>
                                <StyledTypography variant="body2" maxWidth={100}>
                                  {toTitleCase(name)}
                                </StyledTypography>
                                <StyledTypography variant="smallText" color="text.secondary" fontWeight="bold">
                                  {formatLeagueName(league, sports)}
                                </StyledTypography>
                              </Stack>
                              <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ flex: 1, textAlign: "center" }}>
                                <StyledTypography variant="label" color="text.secondary" lineHeight="12px">
                                  {getTeamName(awayTeam, league, true)}
                                </StyledTypography>
                                <StyledTypography variant="label" color="text.secondary" lineHeight="12px">
                                  {getTeamName(homeTeam, league, true)}
                                </StyledTypography>
                              </Stack>
                              <Stack flexDirection="column" alignItems="flex-end" px={1} sx={{ flex: 0.5, textAlign: 'center' }}>
                                {gameLiveData[gameId] ? (
                                  <Stack direction="row" alignItems="center" gap={0.5}>
                                    <StyledTypography color="text.secondary" variant="label" lineHeight="12px" textAlign="left" pr={2}>
                                      {startDateTime ? (
                                        <>
                                          Live
                                          <br />
                                          <b>{formatDateTimeYear(convertUTCToLocalTime(startDateTime)!).split(',')[0]}</b>
                                        </>
                                      ) : null}
                                    </StyledTypography><Box display="flex" position="absolute" right={10}>
                                      <SensorsIcon color="error" sx={{
                                        backgroundColor: theme.palette.grayScale.sixpercent,
                                        borderRadius: "6px",
                                        p: 0.3,
                                        fontSize: 18,
                                      }} />
                                    </Box></Stack>
                                ) : (
                                  <StyledTypography color="text.secondary" variant="label" lineHeight="12px">
                                    {startDateTime ? (
                                      <>
                                        {formatDateTimeYear(convertUTCToLocalTime(startDateTime)!).split(',')[0]}
                                        <br />
                                        <b>{formatDateTimeYear(convertUTCToLocalTime(startDateTime)!).split(',')[1]}</b>
                                      </>
                                    ) : null}
                                  </StyledTypography>
                                )}
                              </Stack>
                            </Stack>
                          </SelectMenu.Item>
                        </React.Fragment>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </motion.div>
          </SelectMenu.List>
        </SelectMenu.Modal>
      </SelectMenu>
    </Box>
  );
}

export default SearchLive;