import { League } from "@/components/Common/Enums/LeagueEnum";

// This function is used to convert the typename of a game type to a League enum
export const getLeagueFromTypename = (typename: string): League => {
    // Define a mapping for special cases
    const specialCases: { [key: string]: League } = {
        'UEFAEuro': League.UEFA_EURO,
        'UEFAEuropa': League.UEFA_EUROPA,
        'EUFAChampions': League.EUFA_CHAMPIONS,
        'NCAABaseball': League.NCAA_BASEBALL,
        // Add other special cases here if needed
    };

    // Check if the typename matches any special case
    const baseTypename = typename.replace('GameType', '');
    if (specialCases[baseTypename]) {
        return specialCases[baseTypename];
    }

    // Remove the 'GameType' suffix from the typename
    const leagueName = baseTypename
        // Add an underscore between lowercase letters followed by uppercase letters or digits
        // For example, 'PremierLeague2021' becomes 'Premier_League_2021'
        .replace(/([a-z])([A-Z0-9])/g, '$1_$2')
        // Convert the resulting string to uppercase
        .toUpperCase();

    // Return the corresponding League enum value
    return League[leagueName as keyof typeof League];
};