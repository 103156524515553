import { useAppSelector } from '@/store/store';
import React from 'react'
import ErrorBoundaryPopup from './ErrorBoundaryPopup';

function CustomErrorBoundary() {
  const { errorMessage } = useAppSelector((state) => state.localStoreReducer);

  if (!errorMessage) return null;

  return <ErrorBoundaryPopup message={errorMessage} />
}

export default CustomErrorBoundary